/** @jsx jsx */
import { jsx } from "theme-ui";

import React, { useState } from "react";
import { Flex, Text, Box, Link } from "theme-ui";
import { useStaticQuery, graphql } from "gatsby";
import { Link as GatsbyLink } from "gatsby";
import styled from "@emotion/styled";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

import Menu from "@gatsbystorefront/gatsby-theme-storefront-shopify/src/components/Menu";
import Search from "@gatsbystorefront/gatsby-theme-storefront-shopify/src/components/Search";
//import ShoppingBag from "./Icons/ShoppingBag";
import { StaticImage } from "gatsby-plugin-image";
import ShoppingCart from "@gatsbystorefront/gatsby-theme-storefront-shopify/src/components/Icons/ShoppingCart";
import strings from "@gatsbystorefront/gatsby-theme-storefront-shopify/src/components/strings.json";

const { ariaShoppingCartLabel, ariaHomaPageLinkLabel } = strings;

const NavbarBase = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1030;
`;

const Nav = styled(NavbarBase)`
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.2);
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
  transition: all 200ms ${(props) => (props.show ? "ease-in" : "ease-out")};
  transform: ${(props) => (props.show ? "none" : "translate(0, -100%)")};
`;

const Navbar = (props) => {
  const [hideNavbarOnScroll, setHideNavbarOnScroll] = useState(true);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      // Note: prevPos.y > -12 is here to fix Nav component disappearing bug
      // due to elastic scrolling/bounce effect in mobile Safari.
      const isShow = currPos.y > prevPos.y || prevPos.y > -12;
      if (isShow !== hideNavbarOnScroll) setHideNavbarOnScroll(isShow);
    },
    [hideNavbarOnScroll],
    null,
    false,
    100
  );

  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          gatsbyStorefrontConfig {
            storeName
            logo {
              url
              width
              height
            }
            menu {
              handle
              id
              link
              name
              parentId
              type
            }
          }
        }
      }
    }
  `);

  const { storeName, logo, menu } =
    data.site.siteMetadata.gatsbyStorefrontConfig;

  return (
    <Nav show={hideNavbarOnScroll}>
      <Box py={[2, 3]} width={1} as="nav" bg="white">
        <Flex
          sx={{
            maxWidth: 1300,
            justifyContent: "center",
            alignItems: "center",
          }}
          mx="auto"
          px={[3, null, 4]}
        >
          <Box sx={{ width: 100 }}>
            <Menu menu={menu} />
          </Box>

          <Link
            as={GatsbyLink}
            to="/"
            aria-label={ariaHomaPageLinkLabel}
            sx={{ textDecoration: "none" }}
            ml="auto"
          >
            {logo ? (
              <img
                src={logo.url}
                width={logo.width}
                height={logo.height}
                alt={storeName}
              />
            ) : (
              <Flex>
                {/* <ShoppingBag
                  width={['25px', '30px']}
                  height={['25px', '30px']}
                  color="primary"
                /> */}
                <StaticImage
                  src="../../../images/icon.png"
                  alt="logo"
                  height={34}
                  placeholder="tracedSVG"
                />
                <Text
                  ml={2}
                  color="primary"
                  sx={{
                    display: ["none", "block"],
                    fontSize: [2, 3],
                    textDecoration: "none",
                  }}
                >
                  {storeName}
                </Text>
              </Flex>
            )}
          </Link>

          <Flex ml="auto" sx={{ width: 100 }}>
            <Box ml="auto">
              <Search width="25px" height="25px" color="primary" />
            </Box>

            <Text
              as={GatsbyLink}
              aria-label={ariaShoppingCartLabel}
              to="/cart"
              sx={{ textDecoration: "none", fontSize: 4 }}
              ml="auto"
            >
              <ShoppingCart width="25px" height="25px" color="primary" />
            </Text>
          </Flex>
        </Flex>
      </Box>
    </Nav>
  );
};

export default React.memo(Navbar);
